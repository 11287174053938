.report-main {
  margin-top: 50px;
  width: 100%;

  .country-select {
    width: 164px;
    height: 43px;
    border-radius: 8px;
    padding: 12px 8px;
    border: 1px solid rgba(224, 231, 237, 1);

    #country {
      color: rgba(189, 189, 189, 1);
      font-weight: 400;
      font-size: 14px;
    }
  }

  .simple-table {
    width: 90%;
    margin-left: auto;
    margin-top: 70px;
    margin-right: auto;
    border-collapse: collapse;
    border-radius: 20px;
    border-spacing: 0;
    border: 1px solid rgba(224, 231, 237, 1);
    overflow-x: auto;

    th {
      background-color: rgba(252, 252, 252, 1);
      font-weight: 600;
      padding: 12px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      color: rgba(51, 51, 51, 1);
    }

    td {
      padding: 12px;
      border-bottom: 1px solid #ddd;
    }

    .square-header {
      position: relative;

      .arrow-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        color: rgba(168, 176, 185, 1);
      }
    }

    .child-checkbox {
      vertical-align: middle;
    }
  }
}

//MEDIA QUERIES

@media screen and (max-width: 568px) {
  .report-main {
    .simple-table {
      width: 100%;
      overflow-x: auto;
    }
  }
}

@media only screen and (min-width: 568px) and (max-width: 820px) {
  .report-main {
    .simple-table {
      width: 100%;
      overflow-x: auto;
    }
  }
}
