.main-profile {
  width: 100%;
  margin-top: 50px;
  margin-left: 50px;

  &__profile-page {
    width: 96%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    display: flex;
    flex-direction: row;
    gap: 20px;

    &__profile-container {
      display: flex;
      flex-direction: column;
      gap: 40px;
      width: 60%;

      &__change-email {
        display: flex;
        flex-direction: column;
        width: 750px;
        border: 1px solid rgba(227, 230, 240, 1);
        height: 308px;
        border-radius: 12px;

        &__title {
          margin-left: 30px;
          margin-top: 30px;
          border-bottom: 1px solid rgba(227, 230, 240, 1);

          h4 {
            color: rgba(0, 0, 0, 1);
            font-weight: 600;
            font-size: 18px;
          }
        }

        &__input-container {
          display: flex;
          flex-direction: column;
          margin-left: 30px;
          margin-top: 30px;

          &__email {
            display: flex;
            margin-top: 30px;

            label {
              color: rgba(0, 0, 0, 1);
              font-weight: 500;
              font-size: 18px;
            }

            input {
              margin-left: 40px;
              width: 304px;
              height: 43px;
              border-radius: 8px;
              border: 1px solid rgba(206, 212, 218, 1);
              padding: 12px;
            }
          }
        }

        &__save-btn {
          width: 166px;
          margin-left: auto;
          margin-top: 20px;

          button {
            width: 100%;
            height: 48px;
            border-radius: 12px;
            padding: 12px 24px;
            background-color: rgba(52, 188, 133, 1);
            border: none;
            color: rgba(255, 255, 255, 1);
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }

    &__profile-pic {
      width: 35%;
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(227, 230, 240, 1);
      height: 214px;
      border-radius: 12px;

      &__pic-title {
        border-bottom: 1px solid rgba(227, 230, 240, 1);
        margin-left: 30px;
        margin-top: 30px;

        h4 {
          color: rgba(0, 0, 0, 1);
          font-weight: 600;
          font-size: 18px;
        }
      }

      &__pic-prof {
        margin-top: 50px;
        margin-left: 50px;
        display: flex;
        gap: 20px;

        &__pic-- {
          img {
            width: 50px;
            height: 60px;
            border-radius: 40px;
            cursor: pointer;
          }
        }

        &__email-- {
          &__username {
            font-weight: bold;
            font-size: 14px;
            cursor: pointer;
          }

          &__useremail {
            color: rgba(0, 0, 0, 0.5);
            font-size: 12px;
            margin-top: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

//MEDIA QUERIES

@media screen and (max-width: 568px) {
  .main-profile {
    margin-left: 0;

    &__profile-page {
      width: 100%;
      flex-direction: column;
      margin-bottom: 10px;

      &__profile-container {
        width: 100%;

        &__change-email {
          width: 400px;

          &__title {
            margin-left: 10px;
          }

          &__input-container {
            margin-left: 10px;

            &__email {
              input {
                margin-left: 10px;
              }
            }
          }
        }
      }
      &__profile-pic {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 568px) and (max-width: 820px) {
  .main-profile {
    &__profile-page {
      width: 100%;
      flex-direction: column;
      margin-bottom: 10px;
      margin-top: 30px;

      &__profile-container {
        width: 100%;

        &__change-email {
          width: 400px;
        }
      }
      &__profile-pic {
        width: 400px;
      }
    }
  }
}

@media only screen and (min-width: 820px) and (max-width: 1440px) {
  .main-profile {
    margin-left: 20px;

    &__profile-page {
      width: 100%;
      flex-direction: column;
      margin-bottom: 10px;
      margin-top: 30px;

      &__profile-container {
        width: 100%;

        &__change-email {
          width: 650px;
        }
      }
      &__profile-pic {
        width: 600px;
      }
    }
  }
}
