* {
  font-family: "General Sans", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.toast-container {
  top: 10% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: rgba(52, 188, 133, 1) !important;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root{
  color: rgba(190, 200, 208, 1) !important;
}
