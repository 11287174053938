.notifications {
  width: 100%;
  margin-top: 50px;
  margin-left: 50px;

  &__notification-intro {
    margin-top: 30px;
  }

  &__notification-container {
    margin-top: 50px;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 30px;

    &__type {
      display: flex;
      gap: 50px;

      label {
        color: rgba(0, 0, 0, 1);
        font-weight: 500;
        font-size: 18px;
      }

      select {
        width: 192px;
        height: 43px;
        border-radius: 8px;
        border: 1px solid rgba(235, 235, 235, 1);
        background-color: rgba(255, 255, 255, 1);
        margin-left: 60px;
      }

      input {
        width: 320px;
        height: 43px;
        border-radius: 8px;
        padding: 12px;
        border: 1px solid rgba(206, 212, 218, 1);
      }

      textarea {
        width: 320px;
        height: 142px;
        border: radius 8px;
        border: 1px solid rgba(206, 212, 218, 1);
      }
    }

    &__buttons {
      display: flex;
      flex-direction: row;
      gap: 10px;
      width: 500px;
      justify-content: center;

      &__send-btn {
        width: 89px;
        height: 48px;
        border-radius: 12px;
        padding: 12px 24px;
        background-color: rgba(244, 244, 244, 1);
        color: rgba(51, 51, 51, 1);
        font-size: 16px;
        cursor: pointer;
        border: none;
      }

      &__review-btn {
        width: 105px;
        height: 48px;
        border-radius: 12px;
        padding: 12px 24px;
        background-color: rgba(46, 92, 254, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        cursor: pointer;
        border: none;
      }

      &__submit-btn {
        width: 105px;
        height: 48px;
        border-radius: 12px;
        padding: 12px 24px;
        background-color: rgba(52, 188, 133, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        cursor: pointer;
        border: none;
      }
    }
  }
}
#location-select {
  margin-left: 30px;
}
#title-input {
  margin-left: 60px;
}
#message {
  margin-left: 20px;
}
@media screen and (max-width: 568px) {
  .notifications {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-bottom: 20px;

    &__notification-container {
      justify-content: center;
      align-items: center;

      &__type {
        gap: 15px;

        input {
          width: 280px;
        }
        textarea {
          width: 200px;
        }
      }
    }
  }
}
