.flagged-nav-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  .nav-section {
    display: flex;
    flex-direction: row;
    width: 475px;
    height: 50px;
    border-radius: 8px;
    padding: 6px;
    background-color: rgba(214, 218, 222, 0.35);
    gap: 12px;

    .nav-head {
      width: 155px;
      height: 38px;
      border-radius: 8px;
      padding: 8px 12px;
      cursor: pointer;

      .nav-head-label {
        font-weight: 500;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.4);
        text-decoration: none;
        text-align: center;
      }
    }

    .nav-head-selected {
      width: 145.5px;
      height: 38px;
      border-radius: 8px;
      padding: 8px 12px;
      background-color: rgba(255, 255, 255, 1);

      .nav-head-label {
        font-weight: 500;
        font-size: 16px;
        color: rgba(41, 45, 50, 1);
        text-decoration: none;
        text-align: center;
      }
    }
  }
}

.status-column {

  .status-icon {
    background-color: rgba(250, 250, 250, 1);
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 8px;
    border: 1px solid rgba(235, 235, 235, 0.807);
    cursor: pointer;
    text-align: center;
    .status-icon-icon {
      color: rgba(159, 165, 180, 1);
    }
    .switch-icon {
      color: rgba(11, 95, 255, 1);
      font-size: 20px;
      cursor: pointer;
    }
  }
  .status-color {
    .color-tag {
      color: #ffbc10;
      background-color: #feffe5;
      padding: 2px;
      text-align: center;
      border-radius: 10px;
      font-weight: 800;
    }
  }
}
