.user-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &__all-users {
    &__intro {
      text-align: center;
      margin-top: 10px;
    }
  }
  &__add-user {
    background-color: rgba(52, 188, 133, 1);
    border-radius: 12px;
    gap: 8px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &__title {
      text-align: center;
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      background-color: rgba(52, 188, 133, 1);
      border: none;
      &__user-add {
        font-size: 16px;
      }
    }
  }
}

.entries {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: auto;
  margin-top: 30px !important;
  width: 95%;
  &__show {
    display: flex;
    flex-direction: row;
    width: 300px;
    gap: 20px;

    &__text {
      margin-top: 10px;
      &__p {
        color: rgba(51, 51, 51, 1);
        font-weight: 400;
        font-size: 14px;
      }
    }

    &__group {
      display: flex;
      gap: 8px;
      width: 72px;
      height: 35px;
      border-radius: 8px;
      border: 1px solid rgba(224, 231, 237, 1);
      justify-content: center;
      align-items: center;
    }

    &__text {
      p {
        color: rgba(51, 51, 51, 1);
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .entries__inputs {
    &__location {
      position: relative;

      &__locationIcon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        color: rgba(189, 189, 189, 1);
        cursor: pointer;
      }

      input {
        width: 150px;
        height: 43px;
        border-radius: 8px;
        padding: 12px 8px;
        border: 1px solid rgba(224, 231, 237, 1);
      }

      input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: rgba(189, 189, 189, 1);
      }
    }

    .entries__inputs__search {
      position: relative;

      &__searchIcon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        color: rgba(189, 189, 189, 1);
        cursor: pointer;
      }

      input {
        width: 250px;
        height: 43px;
        border-radius: 8px;
        padding: 12px 8px;
        border: 1px solid rgba(224, 231, 237, 1);
      }

      input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: rgba(189, 189, 189, 1);
      }
    }
  }
}

.table__container {
  width: 95%;
  margin: auto;
  margin-top: 20px;
  overflow-x: auto;
  border-radius: 15px;
  border: 1px solid rgba(224, 231, 237, 1);
  .table__container__data-table {
    border: 1px solid rgba(224, 231, 237, 1);
    border-radius: 12px;
    width: 100%;
    border-collapse: collapse;
  }

  .table__container__data-table th {
    border-bottom: 1px solid rgba(224, 231, 237, 1);
    color: rgba(51, 51, 51, 1);
    text-align: left;
    padding: 10px;
  }
}

.table-header {
  background-color: rgba(252, 252, 252, 1);
  border-bottom: 1px solid rgba(224, 231, 237, 1);
  .header-label {
    font-weight: 600;
  }
}

.arrows {
  margin-right: 10px;
  color: rgba(168, 176, 185, 1);
}

.t-body {
  margin-top: 20px;

  margin-bottom: 20px;
  text-align: center;
}

.mother-checkbox {
  color: rgba(224, 231, 237, 1);
  font-size: 25px;
  cursor: pointer;
}

.tr {
  border-bottom: 1px solid rgba(224, 231, 237, 1);
}

.user-name-data {
  color: rgba(51, 51, 51, 1);
  font-weight: 400;
  font-size: 14px;
}

.open-button {
  background-color: rgba(52, 188, 133, 1);
  width: 60px;
  height: 29px;
  border-radius: 12px;
  padding: 4px 12px;
  color: rgba(255, 255, 255, 1);
  font-weight: 500;
  font-size: 14px;
  border: none;
  cursor: pointer;
}

.selected {
  color: green;
}

td {
  padding: 10px;
}

.eye-icon-container {
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background-color: rgba(250, 250, 250, 1);
  padding: 5px;
  border: 1px solid rgba(235, 235, 235, 1);
  cursor: pointer;
  text-align: center;
}

.status-icon-icon {
  color: rgba(159, 165, 180, 1);
  cursor: pointer;
}
.switch-icon {
  // color: rgba(11, 95, 255, 1);
  font-size: 20px;
  cursor: pointer;
}

.disabled {
  color: red;
}
.action-column {
  width: 30px;
  height: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 1px solid rgba(235, 235, 235, 1);
  cursor: pointer;
  background-color: rgba(235, 87, 87, 1);
}

.delete-icon {
  color: rgba(255, 255, 255, 1);
  font-size: 15px;
  cursor: pointer;
}

//  RESPONSIVENESS

@media (max-width: 568px) {
  .dash-main-cont {
    flex-direction: column;
    align-items: center;
  }

  .user-section {
    width: 100%;
    margin-top: 50px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .entries {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    .entries__show {
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
    }

    .entries__inputs {
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .table-container {
    width: 100%;
    margin-top: 20px;
    overflow-x: auto;

    .data-table {
      width: 100%;
      .square-header {
        width: auto;
        text-align: center;
      }
    }
  }
}

@media only screen and (min-width: 568px) and (max-width: 820px) {
  .dash-main-cont {
    flex-direction: column;
    align-items: center;
  }

  .user-section {
    width: 100%;
    margin-top: 50px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .entries {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    .entries__show {
      width: auto;
    }

    .entries__inputs {
      width: auto;
    }
  }

  .table-container {
    width: 100%;
    margin-top: 20px;
    overflow-x: auto;

    .data-table {
      border: 1px solid rgba(224, 231, 237, 1);
      border-radius: 12px;
      padding: 10px;
      width: 100%;

      .square-header {
        white-space: nowrap;
        text-align: left;
        width: 100%;
        height: 56px;
        padding: 10px;
        position: relative;
        color: rgba(51, 51, 51, 1);
        font-weight: 600;
        border-bottom: 1px solid rgba(224, 231, 237, 1);

        .arrow-icon {
          color: rgba(224, 231, 237, 1);
          margin-left: 10px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 820px) and (max-width: 1024px) {
  .dash-main-cont {
    flex-direction: column;
    align-items: center;
  }

  .user-section {
    width: 100%;
    margin-top: 50px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .entries {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    .entries__show {
      width: auto;
    }

    .entries__inputs {
      width: auto;
    }
  }

  .table-container {
    width: 100%;
    margin-top: 20px;
    overflow-x: auto;

    .data-table {
      border: 1px solid rgba(224, 231, 237, 1);
      border-radius: 12px;
      padding: 10px;
      width: 100%;

      .square-header {
        white-space: nowrap;
        text-align: left;
        width: 100%;
        height: 56px;
        padding: 10px;
        position: relative;
        color: rgba(51, 51, 51, 1);
        font-weight: 600;
        border-bottom: 1px solid rgba(224, 231, 237, 1);

        .arrow-icon {
          color: rgba(224, 231, 237, 1);
          margin-left: 10px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
        }
      }
    }
  }
}

.user-section-heading {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .heading {
    .label {
      margin-top: 10px;
    }
  }
  .add-user {
    a {
      text-decoration: none !important;
    }
    .add-user-button {
      background-color: rgba(52, 188, 133, 1);
      border-radius: 12px;
      padding: 1rem;
      width: 183px;
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
      border: none;
      color: white;
      font-size: medium;
      cursor: pointer;
    }
  }
}

.status-column {
  .status-icon {
    background-color: rgba(250, 250, 250, 1);
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 8px;
    border: 1px solid rgba(235, 235, 235, 0.807);
    cursor: pointer;
    text-align: center;
    .status-icon-icon {
      color: rgba(159, 165, 180, 1);
    }
    .switch-icon {
      color: rgba(11, 95, 255, 1);
      font-size: 20px;
      cursor: pointer;
    }
  }
  .status-color {
    .color-tag {
      color: #ffbc10;
      background-color: #feffe5;
      padding: 2px;
      text-align: center;
      border-radius: 10px;
      font-weight: 800;
    }
  }
}

.table__container {
  .data-table {
    .table-body {
      .table-row {
        .table-cell {
          .checkbox {
            border-color: rgba(190, 200, 208, 1) !important;
          }
        }
      }
    }
  }
}
.a:-webkit-any-link {
  text-decoration: none !important;
}

input[type="checkbox"] {
  border-color: rgba(190, 200, 208, 1) !important;
}
