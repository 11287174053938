.entries-activity {
  display: flex;
  // flex-direction: row;
  gap: 0px;
  width: 90%;
  margin:auto;
  margin-top: 30px;
  justify-content: flex-end;
  &__show {
    display: flex;
    flex-direction: row;
    width: 300px;
    
    &__text {
      margin-top: 10px;
      &__p {
        color: rgba(51, 51, 51, 1);
        font-weight: 400;
        font-size: 14px;
      }
    }

    &__group {
      display: flex;
      gap: 8px;
      width: 72px;
      height: 35px;
      border-radius: 8px;
      border: 1px solid rgba(224, 231, 237, 1);
      justify-content: center;
      align-items: center;
    }

    &__text-- {
      p {
        color: rgba(51, 51, 51, 1);
        font-weight: 400;
        font-size: 14px;
        margin-top: 10px;
      }
    }
  }

  &__inputs {
    display: flex;
    justify-content: flex-end;
    width: 50%;

    &__location-- {
      input,
      select {
        width: 150px;
        height: 43px;
        border-radius: 8px;
        padding: 12px 8px;
        border: 1px solid rgba(224, 231, 237, 1);
      }

      input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: rgba(189, 189, 189, 1);
      }

      input,
      select:focus {
        border: 1px solid rgba(224, 231, 237, 1);
      }
    }

    &__search {
      position: relative;

      &-searchIcon {
        position: absolute;
        top: 40%;
        right: 20px;
        transform: translateY(-50%);
        color: rgba(189, 189, 189, 1);
        cursor: pointer;
      }

      input {
        width: 250px;
        height: 43px;
        border-radius: 8px;
        padding: 12px 8px;
        margin-right: 10px;
        border: 1px solid rgba(224, 231, 237, 1);

      }

      input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: rgba(189, 189, 189, 1);
      }

      input:focus {
        border: 1px solid rgba(224, 231, 237, 1);
      }
    }
  }
  &__csv-div {
    // margin-top: 10px;
    &__csv-button {
      display: flex;
      width: 150px;
      height: 43px;
      border-radius: 8px;
      border: none;
      padding: 12px;
      background-color: white;
      color: rgba(52, 188, 133, 1);
      border: 1px solid rgba(52, 188, 133, 1);
      cursor: pointer;
      text-decoration: none;
      svg{
        margin-right: 5px;
      }
    }
  }
}

.activity-nav-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  .nav-section {
    display: flex;
    flex-direction: row;
    width: 300px;
    height: 50px;
    border-radius: 8px;
    padding: 6px;
    background-color: rgba(214, 218, 222, 0.35);
    gap: 12px;

    .nav-head {
      width: 155px;
      height: 38px;
      border-radius: 8px;
      padding: 8px 12px;
      // background-color: rgba(255, 255, 255, 1);
      cursor: pointer;

      .nav-head-label {
        font-weight: 500;
        font-size: 16px;
        color: rgba(41, 45, 50, 1);
        text-decoration: none;
        text-align: center;
      }
    }

    .nav-head-selected {
      width: 155px;
      height: 38px;
      border-radius: 8px;
      padding: 8px 12px;
      background-color: rgba(255, 255, 255, 1);
      cursor: pointer;

      .nav-head-label {
        font-weight: 500;
        font-size: 16px;
        color: rgba(41, 45, 50, 1);
        text-decoration: none;
        text-align: center;
      }
    }
  }
}
.table-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  overflow-x: auto;

  .data-table {
    border: 1px solid rgba(224, 231, 237, 1);
    border-radius: 12px;
    padding: 10px;
    width: 100%;

    .square-header {
      white-space: nowrap;
      text-align: left;
      width: 1074px;
      height: 56px;
      padding: 10px;
      position: relative;
      color: rgba(51, 51, 51, 1);
      font-weight: 600;
      border-bottom: 1px solid rgba(224, 231, 237, 1);

      .arrow-icon {
        color: rgba(224, 231, 237, 1);
        margin-left: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -20px;
      }
    }

    .mother-checkbox {
      color: rgba(224, 231, 237, 1);
      font-size: 25px;
    }

    .child-checkbox {
      color: rgba(224, 231, 237, 1);
      margin-left: 10px;
      font-size: 25px;
      cursor: pointer;
    }

    .square-cell {
      white-space: nowrap;
      padding: 10px;
      text-align: center;
      border-bottom: 1px solid rgba(224, 231, 237, 1);
.action-column-activity{
  // margin: auto;
  margin-left: 15px;
}
      .user-name-data {
        color: rgba(51, 51, 51, 1);
        font-weight: 400;
        font-size: 14px;
      }

      .open-button {
        background-color: rgba(52, 188, 133, 1);
        width: 60px;
        height: 29px;
        border-radius: 12px;
        padding: 4px 12px;
        color: rgba(255, 255, 255, 1);
        font-weight: 500;
        font-size: 14px;
        border: none;
        cursor: pointer;
      }

      .status-column {
        .status-icon {
          background-color: rgba(250, 250, 250, 1);
          width: 30px;
          height: 30px;
          padding: 5px;
          border-radius: 8px;
          border: 1px solid rgba(235, 235, 235, 1);
          cursor: pointer;

          .status-icon-icon {
            color: rgba(159, 165, 180, 1);
          }

          .switch-icon {
            color: rgba(11, 95, 255, 1);
            font-size: 20px;
            cursor: pointer;
          }
        }
      }

      .action-column-activity {
        width: 70px;
        height: 35px;
        border-radius: 8px;
        padding: 8px;
        background-color: rgb(52, 188, 133);
        color: rgba(255, 255, 255, 1);
        cursor: pointer;
        text-align: center;
        .csv-button{
          text-decoration: none;
          color: #ffffff;
          text-align: center;
        }
        .delete-icon {
          color: rgba(255, 255, 255, 1);
          font-size: 15px;
          cursor: pointer;
        }
      }
    }
  }
}

.pagination {
  margin-top: 30px;
  margin-left: 50px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  .show-entries {
    p {
      color: rgba(51, 51, 51, 1);
      font-weight: 400;
      font-size: 12px;
    }
  }

  .page-pagination {
    margin-right: 100px;

    .pagination-container {
      display: inline-block;
      list-style-type: none;
      margin: 0;
      padding: 0;
      white-space: nowrap;
    }

    .pagination-container li {
      display: inline;
      margin-right: 10px;
      cursor: pointer;
    }

    .active {
      font-weight: bold;
    }
  }
}

@media (max-width: 568px) {
  .entries-activity {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__show {
      width: 100%;
      margin-left: 0;
      justify-content: center;
    }

    &__inputs {
      width: 100%;
      margin-top: 20px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 15px;
    }
  }

  .table-container {
    width: 100%;
    overflow-x: auto;

    .data-table {
      .square-header {
        width: auto;
        text-align: center;
      }
    }
  }

  .pagination {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    .show-entries {
      margin-bottom: 10px;
    }

    .page-pagination {
      margin-right: 0;
    }
  }
}

@media only screen and (min-width: 568px) and (max-width: 820px) {
  .entries-activity {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__show {
      width: 100%;
      margin-left: 0;
      justify-content: center;
    }

    &__inputs {
      width: 100%;
      margin-top: 20px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 15px;
    }
  }

  .table-container {
    width: 100%;
    overflow-x: auto;

    .data-table {
      .square-header {
        width: auto;
        text-align: center;
      }
    }
  }

  .pagination {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

    .show-entries {
      margin-bottom: 10px;
    }

    .page-pagination {
      margin-right: 0;
    }
  }
}

@media only screen and (min-width: 820px) and (max-width: 1200px) {
  .entries-activity {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__show {
      width: 100%;
      margin-left: 0;
      justify-content: center;
    }

    &__inputs {
      width: 100%;
      margin-top: 20px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 15px;
    }
  }
}
