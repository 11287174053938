.nav {
  width: 100%;
}

.main-nav {
  width: 100%;
  display: flex;
  height: 1.5cm;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  justify-content: flex-end;
  margin-top: 10px;
  border-bottom: 1px solid rgba(159, 165, 180, 1);
}

.menu {
  font-size: 20px;
  cursor: pointer;
}

.nav-details {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.search input {
  width: 287px;
  height: 48px;
  border-radius: 8px;
  padding: 12px;
  border: 1px solid #ebebeb;
}

.search input::placeholder {
  font-size: 16px;
}

.search .search-icon {
  margin-left: -30px;
  cursor: pointer;
}

.bell {
  background-color: #f8f9fc;
  width: 30px;
  height: 27px;
  border-radius: 10px;
  padding: 5px;
}

.icon-icon {
  cursor: pointer;
  font-size: 21px;
}

.avatar {
  width: 80px;
  height: 50px;
}

.avatar-img {
  width: 40px;
  height: 45px;
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .search {
    display: none;
  }

  .side-search {
    display: block;
  }

  .side-search input {
    width: 155px;
    height: 40px;
    border-radius: 8px;
    padding: 12px;
    border: 1px solid #ebebeb;
  }

  .side-search input::placeholder {
    font-size: 12px;
  }

  .side-search .search-icon {
    margin-left: -25px;
    cursor: pointer;
  }

  .starter-side {
    width: 250px;
  }
}

@media screen and (min-width: 501px) {
  .side-import {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .side-import {
    display: none;
  }
}

@media only screen and (min-width: 568px) and (max-width: 768px) {
  .nav {
    margin-left: 30px;
  }

  .menu-toggle {
    display: none;
  }
  .nav-details {
    margin-left: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .menu-toggle {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .menu-toggle {
    display: none;
  }
}
