.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-contents-edit {
  background-color: #fff;
  width: 100%;
  max-width: 600px;
  height: 300px !important;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.edit-nav {
  width: 100%;
  height: 1cm;
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.nav-title h2 {
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  font-size: 20px;
}

.nav-close .class-icon {
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
  cursor: pointer;
}

.edit-form {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-top: 1px solid rgba(227, 230, 240, 1);

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 30px;
    .id {
      width: 100%;
      display: flex;
      justify-content: space-between;
      label {
        color: rgba(0, 0, 0, 1);
        font-weight: 500;
        font-size: 16px;
        width: 20%;
        margin-top: 10px;
      }
      .input-id {
        width: 78%;
        padding: 12px;
        height: 43px;
        border-radius: 8px;
        border: 1px solid rgba(206, 212, 218, 1);
        cursor: not-allowed;
      }
      .input-id::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: rgba(130, 130, 130, 1);
      }

      .name-id-input {
        padding: 12px;
        width: 78%;
        height: 43px;
        border-radius: 8px;
        border: 1px solid rgba(206, 212, 218, 1);
      }

      .name-id-input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: rgba(130, 130, 130, 1);
      }

    }
    .okay-btn {
      margin-top: 20px;
      float: right;
      padding: 12px 24px;
      border-radius: 12px;
      color: white;
      border: none;
      background-color: rgba(52, 188, 133, 1);
      cursor: pointer;
    }
  }
}
