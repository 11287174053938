.user-section-pi {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;

  &__items {
    display: flex;
    flex-direction: row;
    width: 320px;
    height: 50px;
    border-radius: 8px;
    padding: 6px;
    background-color: rgba(214, 218, 222, 0.35);
    gap: 12px;

    &__sold {
      width: 145.5px;
      height: 38px;
      border-radius: 8px;
      padding: 8px 12px;
      background-color: rgba(255, 255, 255, 1);
      cursor: pointer;
      text-align: center;
      text-decoration: none;

      a,
      &__pi-items {
        font-weight: 500;
        font-size: 16px;
        color: rgba(41, 45, 50, 1);
        text-decoration: none;
        text-align: center;
      }
    }

    &__purchase {
      width: 145.5px;
      height: 38px;
      border-radius: 8px;
      padding: 8px 12px;
      text-decoration: none;

      a,
      &__pi-items-purchased {
        font-weight: 500;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.4);
        text-decoration: none;
        text-align: center;
      }
    }
  }
}

.sold-button {
  width: 77px;
  height: 31px;
  border-radius: 100px;
  padding: 8px 12px;
  background-color: rgba(255, 240, 240, 1);
  border: none;
  color: rgba(235, 87, 87, 1);
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}

.available-button {
  width: 77px;
  height: 31px;
  border-radius: 100px;
  padding: 8px 12px;
  background-color: rgba(240, 255, 248, 1);
  border: none;
  color: rgba(24, 171, 86, 1);
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
}

//  RESPONSIVENESS

@media (max-width: 568px) {
  .dash-main-cont {
    flex-direction: column;
    align-items: center;
  }

  .user-section {
    width: 100%;
    margin-top: 50px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .entries {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    .entries__show {
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
    }

    .entries__inputs {
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .table-container {
    width: 100%;
    margin-top: 20px;
    overflow-x: auto;

    .data-table {
      width: 100%;
      .square-header {
        width: auto;
        text-align: center;
      }
    }
  }
}

@media only screen and (min-width: 568px) and (max-width: 820px) {
  .dash-main-cont {
    flex-direction: column;
    align-items: center;
  }

  .user-section {
    width: 100%;
    margin-top: 50px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .entries {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    .entries__show {
      width: auto;
    }

    .entries__inputs {
      width: auto;
    }
  }

  .table-container {
    width: 100%;
    margin-top: 20px;
    overflow-x: auto;

    .data-table {
      border: 1px solid rgba(224, 231, 237, 1);
      border-radius: 12px;
      padding: 10px;
      width: 100%;

      .square-header {
        white-space: nowrap;
        text-align: left;
        width: 100%;
        height: 56px;
        padding: 10px;
        position: relative;
        color: rgba(51, 51, 51, 1);
        font-weight: 600;
        border-bottom: 1px solid rgba(224, 231, 237, 1);

        .arrow-icon {
          color: rgba(224, 231, 237, 1);
          margin-left: 10px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 820px) and (max-width: 1024px) {
  .dash-main-cont {
    flex-direction: column;
    align-items: center;
  }

  .user-section {
    width: 100%;
    margin-top: 50px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: center;
  }

  .entries {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;

    .entries__show {
      width: auto;
    }

    .entries__inputs {
      width: auto;
    }
  }

  .table-container {
    width: 100%;
    margin-top: 20px;
    overflow-x: auto;

    .data-table {
      border: 1px solid rgba(224, 231, 237, 1);
      border-radius: 12px;
      padding: 10px;
      width: 100%;

      .square-header {
        white-space: nowrap;
        text-align: left;
        width: 100%;
        height: 56px;
        padding: 10px;
        position: relative;
        color: rgba(51, 51, 51, 1);
        font-weight: 600;
        border-bottom: 1px solid rgba(224, 231, 237, 1);

        .arrow-icon {
          color: rgba(224, 231, 237, 1);
          margin-left: 10px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
        }
      }
    }
  }
}

.table__container {
  width: 95% !important;
  margin: auto;
  margin-top: 20px;
  overflow-x: auto;
  border-radius: 15px;
  border: 1px solid rgba(224, 231, 237, 1);
  .table__container__data-table {
    border: 1px solid rgba(224, 231, 237, 1);
    border-radius: 12px;
    width: 100%;
    border-collapse: collapse;
  }

  .table__container__data-table th {
    border-bottom: 1px solid rgba(224, 231, 237, 1);
    color: rgba(51, 51, 51, 1);
    text-align: left;
    padding: 10px;
  }
}

.table__container {
  width: 95%;
  margin: auto;
  margin-top: 20px;
  overflow-x: auto;
  border-radius: 15px;
  border: 1px solid rgba(224, 231, 237, 1);
  .data-table {
    border: 1px solid rgba(224, 231, 237, 1);
    border-radius: 12px;
    width: 100%;
    border-collapse: collapse;
    .table-body {
      .table-row {
        .table-data {
          color: rgba(51, 51, 51, 1);
          font-weight: 400;
          font-size: 14px;
        }
        .table-cell {
          .checkbox {
            border-color: rgba(190, 200, 208, 1) !important;
          }
        }
      }
    }
  }
}
.a:-webkit-any-link {
  text-decoration: none !important;
}

input[type="checkbox"] {
  border-color: rgba(190, 200, 208, 1) !important;
}
