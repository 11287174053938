.categories {
  width: 100%;
  margin-top: 40px;
  &__container {
    margin-top: 50px;
    margin: auto;
    width: 95%;

    &__header {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__header-nav {
        display: flex;
        flex-direction: row;

        &__header-nav__item {
          color: rgba(0, 0, 0, 0.5);
          font-weight: 400;
          font-size: 18px;
        }

        &__header-nav__icon {
          color: rgba(0, 0, 0, 0.5);
          font-size: 23px;
        }

        &__header-link {
          text-decoration: none;

          &__h2 {
            color: rgba(0, 0, 0, 0.5);
            font-weight: 400;
            font-size: 18px;
            cursor: pointer;
          }
        }
      }

      &__header-title {
        &__header-title__text {
          color: rgba(0, 0, 0, 1);
          font-weight: 600;
          font-size: 32px;
        }
      }
    }

    .main-category {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 50px;

      .parent-category {
        display: flex;
        flex-direction: column;

        .category-p {
          color: rgba(0, 0, 0, 0.25);
          font-weight: 500;
          font-size: 18px;
        }

        .add-parent-category {
          display: flex;
          flex-direction: row;
          margin-top: 20px;

          &__label {
            color: rgba(0, 0, 0, 1);
            font-weight: 500;
            font-size: 18px;
            margin-top: 10px;
          }

          &__input {
            width: 321px;
            height: 43px;
            border-radius: 8px;
            padding: 12px;
            border: 1px solid rgba(206, 212, 218, 1);
            margin-left: 50px;
            background-color: rgba(255, 255, 255, 1);
          }

          &__heading {
            margin-left: 20px;
            color: rgba(52, 188, 133, 1);
            font-weight: 500;
            font-size: 18px;
            text-align: center;
            cursor: pointer;
            margin-top: 10px;

            &__icon {
              font-size: 22px;
              text-align: center;
            }
          }
        }
      }

      .sub-category {
        .category-p {
          color: rgba(0, 0, 0, 0.25);
          font-weight: 500;
          font-size: 18px;
        }

        .select-parent {
          margin-top: 20px;
          display: flex;

          &__label {
            color: rgba(0, 0, 0, 1);
            font-weight: 500;
            font-size: 18px;
            margin-top: 10px;
          }

          &__input {
            width: 321px;
            height: 43px;
            border-radius: 8px;
            padding: 12px;
            border: 1px solid rgba(206, 212, 218, 1);
            background-color: rgba(255, 255, 255, 1);
            margin-left: 30px;
          }
        }

        .add-sub-category {
          margin-top: 40px;
          display: flex;

          &__label {
            color: rgba(0, 0, 0, 1);
            font-weight: 500;
            font-size: 18px;
            margin-top: 10px;
          }

          &__input {
            width: 321px;
            height: 43px;
            border-radius: 8px;
            padding: 12px;
            border: 1px solid rgba(206, 212, 218, 1);
            margin-left: 50px;
            background-color: rgba(255, 255, 255, 1);
          }

          &__heading {
            margin-left: 20px;
            color: rgba(52, 188, 133, 1);
            font-weight: 500;
            font-size: 18px;
            text-align: center;
            cursor: pointer;
            margin-top: 10px;
            &__icon {
              font-size: 20px;
            }
          }
        }
      }
    }

    .edit-icon,
    .action-arrow {
      color: #007bff;
    }

    .checkbox-cell,
    .checkbox-cell .child-checkbox {
      padding: 10px;
    }

    .child-checkbox {
      font-size: 1.2rem;
    }
  }
}

.entries_category {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: auto;
  margin-top: 30px !important;
  width: 100%;
  &__show {
    display: flex;
    flex-direction: row;
    width: 300px;
    gap: 20px;

    &__text {
      margin-top: 10px;
      &__p {
        color: rgba(51, 51, 51, 1);
        font-weight: 400;
        font-size: 14px;
      }
    }

    &__group {
      display: flex;
      gap: 8px;
      width: 72px;
      height: 35px;
      border-radius: 8px;
      border: 1px solid rgba(224, 231, 237, 1);
      justify-content: center;
      align-items: center;
    }

    &__text {
      p {
        color: rgba(51, 51, 51, 1);
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .entries_category__inputs {
    // display: flex;
    // margin-right: 30px;
    // gap: 20px;
    // width: 50%;
    // justify-content: flex-end;

    &__location {
      position: relative;

      &__locationIcon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        color: rgba(189, 189, 189, 1);
        cursor: pointer;
      }

      input {
        width: 150px;
        height: 43px;
        border-radius: 8px;
        padding: 12px 8px;
        border: 1px solid rgba(224, 231, 237, 1);
      }

      input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: rgba(189, 189, 189, 1);
      }
    }

    .entries_category__inputs__search {
      position: relative;

      &__searchIcon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        color: rgba(189, 189, 189, 1);
        cursor: pointer;
      }

      input {
        width: 250px;
        height: 43px;
        border-radius: 8px;
        padding: 12px 8px;
        border: 1px solid rgba(224, 231, 237, 1);
      }

      input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: rgba(189, 189, 189, 1);
      }
    }
  }
}

.table__container {
  width: 100%;
  .data-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid rgba(224, 231, 237, 1);
    .table-body {
      .table-row {
        .parent-row {
          color: rgba(51, 51, 51, 1);
          font-size: 12px;
        }
  
        .sub-data {
          color: rgba(130, 130, 130, 1);
          font-weight: 400;
          font-size: 14px;
        }
  
        .edit-btn {
          background-color: rgba(255, 188, 16, 1);
          width: 50px;
          height: 29px;
          border-radius: 12px;
          border: none;
          padding: 4px 12px;
          color: rgba(255, 255, 255, 1);
          font-weight: 500;
          font-size: 14px;
          cursor: pointer;
        }
  
        .action-column {
          width: 30px;
          height: 30px;
          border-radius: 8px;
          padding: 8px;
          background-color: rgba(235, 87, 87, 1);
  
          .delete-icon {
            color: rgba(255, 255, 255, 1);
            font-size: 15px;
            cursor: pointer;
          }
        }
  
        .table-cell {
          .checkbox {
            border-color: rgba(190, 200, 208, 1) !important;
          }
        }
      }
    }
  }
}
.a:-webkit-any-link {
  text-decoration: none !important;
}

input[type="checkbox"] {
  border-color: rgba(190, 200, 208, 1) !important;
}
