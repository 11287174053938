.main-container {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.side-main-container {
  width: 20%;
   border-right: 1px solid #e3e6f0;
}

.nav-dash-main-container {
  width: 80%;
}

@media screen and (max-width: 568px) {
  .side-main-container {
    width: 100%;
  }

  .nav-dash-main-container {
    width: 100%;
  }
}

@media only screen and (min-width: 568px) and (max-width: 768px) {
  .side-main-container {
    width: 30%;
  }

  .nav-dash-main-container {
    width: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .side-main-container {
    width: 30%;
  }

  .nav-dash-main-container {
    width: 70%;
  }
}
