.c-side-bar {
  height: 100%;
  max-width: 300px;
  width: 100%;
  // border-right: 1px solid #e3e6f0;

  display: flex;
  flex-direction: column;

  &__header {
    margin: 40px auto;
    display: flex;

    &-logo {
      margin-right: 100px;
    }
  }

  &__body {
    font-size: 16px;
    font-weight: 700;

    &-item {
      margin: 8px 32px;
      height: 48px;
      display: flex;
      border-radius: 12px;
      color: #9fa5b4;
      transition-duration: 0.3s;

      text-decoration: none;

      &:hover {
        background-color: #f0f0f0;
      }

      &-icon {
        margin: auto 13px;
      }

      &-name {
        margin: auto auto auto 0;
      }

      &--active {
        background: rgba(52, 188, 133, 0.1);
        color: #34bc85;

        &-icon {
          color: #34bc85;
        }
        &:hover {
          background: rgba(52, 188, 134, 0.256);
        }
      }
    }
  }

  &__footer {
    margin-top: auto;
    margin: auto 20px 25px 32px;
    display: flex;

    &-menu {
      margin: auto;
    }

    &-user {
      &-avatar {
        width: 42px;
        height: 42px;
      }

      &-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        margin: auto 14px auto 12px;

        text-align: left;
        display: flex;

        &-name {
          font-size: 14px;
          line-height: 16px;
          color: #000000;
          margin: 2px auto 2px 0;
        }

        &-email {
          font-size: 12px;
          line-height: 14px;
          color: rgba(0, 0, 0, 0.5);
          margin: 2px auto 2px 0;
        }
      }
    }
  }
}
