.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-contents-product {
  background-color: #fff;
  padding: 0px !important;
  width: 50%;
  max-width: 550px !important;
  height: 620px !important;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.product {
  &__product-nav {
    width: 100%;
    padding: 20px;
    height: 1cm;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    &__nav-title {
      h2 {
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        font-size: 20px;
      }
    }

    &__nav-close {
      &__class-icon {
        font-size: 20px;
        color: rgba(0, 0, 0, 1);
        cursor: pointer;
      }
    }
  }
  &__line {
    margin-top: 15px;
    border-bottom: 1px solid rgba(206, 212, 218, 0.5);
  }

  &__product-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-bottom: 1px solid rgba(206, 212, 218, 0.5);
    border-top: 1px solid rgba(206, 212, 218, 0.5);

    &__name {
      display: flex;
      justify-content: space-between;
      width: 100%;
      label {
        color: rgba(0, 0, 0, 1);
        font-weight: 500;
        font-size: 16px;
        margin-top: 10px;
        width: 30%;
      }

      input {
        width: 68%;
        padding: 12px;
        color: rgba(130, 130, 130, 1);
        height: 43px;
        border-radius: 8px;
        border: 1px solid rgba(206, 212, 218, 1);
        cursor: not-allowed;
      }

      input::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: rgba(130, 130, 130, 1);
      }
    }
  }

  &__okay-btn {
    margin-top: 20px;
    display: flex;
    align-self: flex-end;
    margin-right: 20px;
    justify-content: flex-end;
    button {
      padding: 12px 24px;
      border-radius: 12px;
      color: white;
      border: none;
      background-color: rgba(52, 188, 133, 1);
      cursor: pointer;
    }
  }
}
