.settings {
  width: 100%;
  margin-left: 50px;
  margin-top: 50px;

  &__settings-intro {
    margin-top: 30px;
  }

  &__settings-container {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    &__users {
      p {
        color: rgba(0, 0, 0, 0.25);
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 20px;
      }

      &__service-charge {
        display: flex;

        label {
          color: rgba(0, 0, 0, 1);
          font-weight: 500;
          font-size: 18px;
        }

        &__input-container {
          display: flex;
          align-items: center;
          position: relative;
          margin-left: 150px;

          input {
            width: 232px;
            height: 43px;
            border-radius: 8px;
            padding: 12px 40px 12px 12px;
            border: 1px solid rgba(206, 212, 218, 1);
          }

          &__percentage {
            position: absolute;
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            color: rgba(0, 0, 0, 0.5);
            background-color: rgba(233, 236, 239, 1);
          }
        }

        p {
          margin-left: 12px;
          color: rgba(52, 188, 133, 1);
          font-weight: 500;
          font-size: 18px;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    &__users-- {
      &__lisitin-fee {
        display: flex;

        label {
          color: rgba(0, 0, 0, 1);
          font-weight: 500;
          font-size: 18px;
        }
        input {
          width: 232px;
          height: 43px;
          border-radius: 8px;
          padding: 12px 40px 12px 12px;
          border: 1px solid rgba(206, 212, 218, 1);
          margin-left: 50px;
        }

        p {
          margin-left: 12px;
          color: rgba(52, 188, 133, 1);
          font-weight: 500;
          font-size: 18px;
          text-align: center;
          cursor: pointer;
        }
      }
    }
  }

  &__btns {
    margin-top: 50px;
    display: flex;
    width: 500px;
    justify-content: center;
    gap: 10px;

    &__save {
      width: 166px;
      height: 48px;
      border-radius: 12px;
      border: none;
      padding: 12px 24px;
      background-color: rgba(52, 188, 133, 1);
      color: rgba(255, 255, 255, 1);
      font-size: 16px;
      cursor: pointer;
    }

    &__cancel {
      width: 106px;
      height: 48px;
      border-radius: 12px;
      border: none;
      cursor: pointer;
      padding: 12px 24px;
      background-color: rgba(244, 244, 244, 1);
      color: rgba(51, 51, 51, 1);
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 568px) {
  .settings {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-bottom: 30px;

    &__settings-container {
      &__users {
        &__service-charge {
          label {
            color: rgba(0, 0, 0, 1);
            font-weight: 500;
            font-size: 16px;
          }

          &__input-container {
            margin-left: 20px;

            input {
              width: 200px;
            }
          }

          p {
            margin-left: 10px;
            font-size: 16px;
          }
        }
      }

      &__users-- {
        &__lisitin-fee {
          label {
            font-size: 16px;
          }
          input {
            width: 200px;
            margin-left: 20px;
          }

          p {
            margin-left: 10px;
            font-size: 16px;
          }
        }
      }
    }

    &__btns {
      margin-top: 50px;
      display: flex;
      width: 420px;
      justify-content: center;
      align-items: center;
      gap: 10px;

      &__save {
        width: 156px;
        height: 48px;
        border-radius: 12px;
        border: none;
        padding: 12px 24px;
        background-color: rgba(52, 188, 133, 1);
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        cursor: pointer;
      }

      &__cancel {
        width: 106px;
        height: 48px;
        border-radius: 12px;
        border: none;
        cursor: pointer;
        padding: 12px 24px;
        background-color: rgba(244, 244, 244, 1);
        color: rgba(51, 51, 51, 1);
        font-size: 16px;
      }
    }
  }
}
