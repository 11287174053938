.side-side-dash {
  width: 100%;
  margin-top: 40px;
}

.dash-main-cont {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.dashboard-menu {
  display: flex;
  width: 300px;
  a{
    text-decoration: none;
  }
}

.dash-nav {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 18px;
}

.dash-intro {
  margin-top: 20px;
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
  font-size: 32px;
}

.dash-body {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.dash-items {
  flex-basis: calc(32% - 20px);
  margin: 10px;
  border: 0.5px solid rgba(159, 165, 180, 1);
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.dash-icons {
  background-color: rgba(254, 177, 46, 0.1);
  padding: 10px;
  border-radius: 15px;
}

.dash-no {
  font-weight: 600;
  font-size: 20px;
  color: rgba(0, 0, 0, 1);
}

.dash-desc {
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 10px;
}

.web-hook {
  color: rgba(254, 177, 46, 1);
  font-size: 30px;
  font-weight: bold;
}

.basket-fill-1 {
  color: rgba(151, 71, 255, 1);
  font-size: 30px;
  font-weight: bold;
}

.ci-home {
  font-size: 30px;
  font-weight: bold;
  color: rgba(254, 121, 46, 1);
}

.cg-notes-1 {
  color: rgba(52, 188, 133, 1);
  font-size: 30px;
  font-weight: bold;
}

.gr-schedule {
  font-size: 30px;
  font-weight: bold;
  color: rgba(46, 92, 254, 0.1);
}

.stop-watch {
  font-size: 30px;
  font-weight: bold;
  color: rgba(75, 46, 254, 0.1);
}

.money-bill {
  font-size: 30px;
  font-weight: bold;
  color: rgba(29, 46, 108, 1);
}

.ci-home-1 {
  font-size: 30px;
  font-weight: bold;
  color: rgba(255, 130, 37, 1);
}

.money-check {
  font-size: 30px;
  font-weight: bold;
  color: rgba(255, 51, 88, 1);
}

.people-fill-1 {
  font-size: 30px;
  font-weight: bold;
  color: rgba(131, 59, 97, 1);
}

.fill-graph-1 {
  font-size: 30px;
  font-weight: bold;
  color: rgba(151, 71, 255, 1);
}

.fill-graph-2 {
  font-size: 30px;
  font-weight: bold;
  color: rgba(54, 134, 247, 1);
}

.web-money {
  font-size: 30px;
  font-weight: bold;
  color: rgba(87, 229, 45, 1);
}

.people-fill-2 {
  font-size: 30px;
  font-weight: bold;
  color: rgba(255, 51, 88, 1);
}

.cg-notes-2 {
  font-size: 30px;
  font-weight: bold;
  color: rgba(254, 177, 46, 1);
}

.pi-flag {
  font-size: 30px;
  font-weight: bold;
  color: rgba(8, 188, 167, 1);
}

.cg-notes-4 {
  color: rgba(99, 207, 229, 1);
  font-size: 30px;
  font-weight: bold;
}

@media screen and (max-width: 568px) {
  .dash-items {
    flex-basis: calc(100% - 20px);
  }
}

@media screen and (min-width: 601px) and (max-width: 900px) {
  .dash-items {
    flex-basis: calc(50% - 20px);
  }
}

@media screen and (min-width: 901px) {
  .dash-items {
    flex-basis: calc(33.33% - 20px);
  }
}
